.f-container{
    justify-content: space-between;
}
.f-left{
    gap: 1rem;
}
.f-menu{
    gap: 1.5rem;
    margin-top: 1.5rem;
    font-weight: 600;
}
@media (max-width: 768) {
    .f-container {
      justify-content: center;
    }
    .f-container > div {
      align-items: center !important;
      text-align: center;
    }
  }